settingsMarkitup = {
	previewParserPath:	'',
	onShiftEnter:		{keepDefault:false, openWith:'\n\n'},
	markupSet: [
		{name:'Negrito', key:'B', openWith:'**', closeWith:'**'},
		{name:'Itálico', key:'I', openWith:'_', closeWith:'_'},
		{separator:'---------------' },
		{name:'Lista de Marcadores', openWith:'- ' },
		{name:'Lista Numerada', openWith:function(markItUp) {
			return markItUp.line+'. ';
		}},
		{separator:'---------------' },
		{name:'Link', key:'L', openWith:'[', closeWith:'[![Título]!]]([![Url:!:http://]!] )' },

	]
}

// mIu nameSpace to avoid conflict.
miu = {
	markdownTitle: function(markItUp, char) {
		heading = '';
		n = $.trim(markItUp.selection||markItUp.placeHolder).length;
		for(i = 0; i < n; i++) {
			heading += char;
		}
		return '\n'+heading;
	}
}